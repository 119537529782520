//SheejaManoj
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// import p3 from 'assests/Photos/Messages/principal_administration_ms_sheeja_manoj.jpg';

// import p1 from 'assests/Photos/achivements/HumanIntrest/H2020/3.png'


const Hero = () => {

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/H2020/3.webp`;
  

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={4} >
      <Grid item container alignItems={'justify'} xs={12} md={6} >
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
        Bengaluru: Braving cancer, boy tastes success with 91%
        </Typography>

        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
             
              color="text.primary"
              align={'justify'}
            ><br/>
              BENGALURU: The success story of Rohit R Iyer, who secured 91% in the CBSE class 10 exams, is not like any other 16-year-old’s. This NPS Yeshwantpur student was fighting a bigger battle — against cancer — when he faced his boards this March. Rohit was diagnosed with acute lymphoblastic leukemia, a form of blood cancer, in November 2019 when he was representing Karnataka at a national table tennis tournament in Uttar Pradesh along with his team. As the event progressed, he experienced unusual fatigue and had to return to Bengaluru to get himself examined.
              <br/><br/>
He had to undergo treatment, including multiple chemotherapy sessions, and take medications high in dosage and side effects. “But as days passed, Rohit began to think about the boards, which were just a month away. While all his friends from school were busy with their books, he was at the peak of the battle with cancer. But Rohit began to channelize every ounce of energy in his body towards exam preparation,” said Ramesh Iyer, his father.
            </Typography>
          </Box>

        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={1} width={1} maxWidth={500}>
            <Box
              component={'img'}
              src={p1}
              
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Grid>
      <Typography
             
             color="text.primary"
             align={'justify'}
           ><br/><br/>
            “I felt that instead of brooding, I have to be as positive and physically active as possible. I had prepared well for a year and thought I should just give it a go. Initially, I just wanted to get it over with. But then I realized I have to do it well in order to get some satisfaction. Since I experienced side-effects like nausea, extreme fatigue, headache and unbearable body aches, I used to pick up my books whenever I found time. That’s when it dawned on me how much studying every day helps,” recalled Rohit. He travelled from hospital, in between his chemotherapy sessions, to write the papers for three hours. “As I had to take medicines every six hours, I had to finish my papers and quickly return to the hospital not to miss the next dose,” he said. Son of an engineer and a PU college lecturer, Rohit has to undergo radiotherapy for another two weeks. And the warrior in him is ready to take it head on.
           </Typography>
    </Grid>
  );
};

export default Hero;
