//Dr. K.P. Gopalkrishna

/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// import p1 from 'assests/Photos/achivements/HumanIntrest/H2020/1.jpg'

const Hero = () => {

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/home-static/static-images/all-static-images/H2020/1.webp`;
  
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems={'justify'} xs={12} md={6}>
        <Typography fontWeight={700} variant={'h4'} align={'center'} >
        Human Interest Story 16 July 2020
        </Typography>

        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              
              color="text.primary"
              align="justify"
            >
             <br/>An article on how Rohit R Iyer, a student of National Public School, Yeshwanthpur, braved the odds to appear for the CBSE Examinations and came out with flying colours was featured in the Times of India dated 16 July 2020.
            </Typography>
            <Typography
              
              color="text.primary"
              align="justify"
            >
            <br/>This glowing tribute to the indefatigable spirit of our student who faced a serious health issue and yet stood up to the challenge of appearing for the X-Std Boards and came out victorious with a commendable 91% made his parents and the school immensely proud. Furthermore, it reaffirmed the faith that we have in ourselves and taught us fortitude and courage to face crises in our lives and march ahead with resilience.
<br/><br/>
The School congratulates Rohit R Iyer on his success and for being an inspiration for others.
            </Typography>
          </Box>

        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={1} width={1} maxWidth={500}>
            <Box
              component={'img'}
              src={
                p1
              }
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Grid>
    
    </Grid>
  );
};

export default Hero;
