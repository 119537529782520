import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {

  Hero,
  Partners,

  Services,
  Users,
} from './components';
import { Divider } from '@mui/material';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const Human2020 = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Container >
          <Hero />
          <Divider />
        </Container>

      </Box>
      <Container maxWidth = {0.78}>
        <Services />
        <Divider />
      </Container>



      <Container maxWidth = {0.78}>
        <Partners />
      </Container>


    </Main>
  );
};

export default Human2020;
